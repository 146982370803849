<template>
    <Page title="Brian test page" icon="mdi-account-multiple">

    </Page>
</template>
<script>
import api from '@/api';

import TeImage from '@/components/common/TEImage';

export default {
    name: 'LogInspections`',

    data() {
        return {
            fileObj: {},
            dataUri: null,
        };
    },
    methods: {
        logForm(formModel) {
            console.log('customer details logForm', formModel);
        },
        toListView() {
            this.$router.push('/auth/admin/customer');
        },
    },
    async created() {
        const result = await api.post(
            this.$store,
            'teimage',
            {
                url:
                    '/live/thumbs/financial/2022/AP/INVOICE-APINV-SUINVABBYY.2021-11-10.49458.pdf.lg.png',
            },
            { 'Content-Type': 'image/png' },
        );
        this.dataUri = `data:Image/png;base64,${result.data}`;
    },
};
</script>
